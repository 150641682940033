<template>
  <div v-if="$isLoading">
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col lg="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            รายละเอียดส่วนลด Tier
          </h1>
        </b-col>
      </CRow>

      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row>
          <b-col md="6">
            <InputText
              textFloat="Tier"
              placeholder="ระบุชื่อ Tier"
              type="text"
              name="metatitle"
              v-model="tierDetail.name"
              isRequired
              :isValidate="$v.tierDetail.name.$error"
              :v="$v.tierDetail.name"
            />
          </b-col>
          <b-col cols="6">
            <label for=""
              >เงื่อนไขส่วนลด<span class="text-danger ml-1">*</span></label
            >

            <b-form-group>
              <b-form-radio-group
                class="mr-4"
                v-model="tierDetail.isDiscountAll"
                name="discount-type"
              >
                <b-form-radio :value="1">ทั้งหมด</b-form-radio>
                <b-form-radio :value="0">แยกหมวดหมู่สินค้า</b-form-radio>
                <b-form-radio :value="2">กำหนดรายสินค้า</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row v-if="tierDetail.isDiscountAll == 1">
          <b-col md="6">
            <InputText
              textFloat="ระบุส่วนลด"
              placeholder="ระบุส่วนลดเป็น %"
              type="number"
              v-model="tierDetail.discountPercent"
              isRequired
              :isValidate="$v.tierDetail.discountPercent.$error"
              :v="$v.tierDetail.discountPercent"
              :maxValue="100"
            />
          </b-col>
        </b-row>
        <b-row v-else-if="tierDetail.isDiscountAll == 0">
          <b-col md="6">
            <InputText
              textFloat="ระบุส่วนลด"
              placeholder="ระบุส่วนลดเป็น %"
              type="number"
              v-model="tierDetail.discountPercent"
              isRequired
              :isValidate="$v.tierDetail.discountPercent.$error"
              :v="$v.tierDetail.discountPercent"
              :maxValue="100"
            />
          </b-col>
          <b-col md="6">
            <div class="d-flex justify-content-end align-items-center">
              <b-button
                :disabled="$v.tierDetail.discountPercent.$error"
                class="btn-main ml-3 mt-4"
                @click="setDiscountAll(tierDetail.discountPercent)"
                >มีผลลัพท์กับทั้งหมด</b-button
              >
            </div>
          </b-col>
          <b-col>
            <b-row class="no-gutters mt-2">
              <b-col>
                <b-table
                  responsive
                  class="text-center table-list"
                  striped
                  :fields="fields"
                  :items="itemsDetail"
                  :busy="isBusy"
                  show-empty
                  empty-text="ไม่พบข้อมูล"
                >
                  <template v-slot:cell(name)="data">
                    <p class="mb-0 nobreak two-lines">
                      {{ data.item.name }}
                    </p>
                  </template>
                  <template v-slot:cell(action)="data">
                    <InputText
                      class="discountText"
                      textFloat=""
                      placeholder="ระบุส่วนลดเป็น %"
                      type="number"
                      @onKeyup="
                        setDiscount(data.index, data.item.discountPercent)
                      "
                      v-model="data.item.discountPercent"
                      :isValidate="
                        $v.itemsDetail.$each.$iter[data.index].discountPercent
                          .$error
                      "
                      :v="
                        $v.itemsDetail.$each.$iter[data.index].discountPercent
                      "
                      :maxValue="100"
                    />
                  </template>
                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-else-if="tierDetail.isDiscountAll == 2">
          <b-col md="4" v-if="id != 0">
            <b-input-group class="panel-input-serach mt-2">
              <b-form-input
                class="input-serach"
                placeholder="ค้นหาชื่อสินค้า"
                v-model="filter.Search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
          <b-col md="4" v-if="id != 0">
            <div class="category-main-box">
              <div
                class="input-serach bg-white category-box mt-2"
                @click="show ? (show = false) : (show = true)"
              >
                <span
                  v-for="(item, index) in dataCategoryHierarchy.breadcrumb"
                  v-bind:key="index"
                  class="nobreak"
                >
                  <span>{{
                    dataCategoryHierarchy.breadcrumb[index]
                      ? dataCategoryHierarchy.breadcrumb[index].name
                      : ""
                  }}</span>
                  <span class="mx-2" v-if="index + 1 < selectCategory.length"
                    >></span
                  >
                </span>
                <span v-if="dataCategoryHierarchy.breadcrumb.length == 0"
                  >หมวดหมู่</span
                >
              </div>
            </div>
          </b-col>
          <b-col
            :md="id != 0 ? 4 : 12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-dropdown
              class="ml-3 p-1 mt-1 lang-dd border-0"
              :text="'ตัวเลือกเพิ่มเติม'"
              right
            >
              <b-dropdown-item-button @click="importProduct()"
                >นำเข้าข้อมูล (.xlsx)</b-dropdown-item-button
              >
              <!-- <b-dropdown-item-button
                >นำออกข้อมูล (.xlsx)</b-dropdown-item-button
              > -->
              <b-dropdown-item-button
                @click="downloadFile()"
                :disabled="isLoadingDownload"
                >ดาวน์โหลดไฟล์ต้นฉบับ</b-dropdown-item-button
              >
            </b-dropdown>
            <b-button
              class="btn-main ml-2 mt-1"
              v-if="id != 0"
              v-b-toggle.sidebar-1
              >ตัวกรอง</b-button
            >
          </b-col>
        </b-row>
        <b-sidebar
          id="sidebar-1"
          title="ตัวกรองสินค้า"
          backdrop
          shadow
          backdrop-variant="dark"
          right
          ref="filterSidebar"
        >
          <div class="px-3 py-2">
            <div class="text-right">
              <button
                type="button"
                class="btn btn-link px-0"
                @click="clearFilter()"
              >
                ล้างค่า
              </button>
            </div>
            <InputText
              class="mb-4"
              textFloat="ส่วนลดเริ่มต้น"
              placeholder="ส่วนลดเริ่มต้น"
              type="number"
              name="discountMin"
              v-model="filter.discountMin"
              isRequired
            />
            <InputText
              class="mb-4"
              textFloat="ส่วนลดสิ้นสุด"
              placeholder="ส่วนลดสิ้นสุด"
              type="number"
              name="discountMax"
              v-model="filter.discountMax"
              isRequired
            />

            <label for="">สถานะการใช้งาน</label>
            <div class="align-items-center mt-2">
              <b-form-radio
                class="mb-2"
                name="type"
                v-model="filter.fetchType"
                :value="2"
                >ทั้งหมด</b-form-radio
              >
              <b-form-radio
                class="mb-2"
                name="type"
                v-model="filter.fetchType"
                :value="1"
                >เปิดใช้งาน</b-form-radio
              >
              <b-form-radio
                class="mb-2"
                name="type"
                v-model="filter.fetchType"
                :value="0"
                >ปิดใช้งาน</b-form-radio
              >
            </div>
            <label for="" class="mt-3">มุมมอง</label>
            <div class="align-items-center mt-2">
              <b-form-radio
                class="mb-2"
                name="tierStatus"
                :value="0"
                v-model="filter.tierStatus"
                >ทั้งหมด</b-form-radio
              >
              <b-form-radio
                class="mb-2"
                name="tierStatus"
                :value="1"
                v-model="filter.tierStatus"
                >เฉพาะสินค้าที่เลือก</b-form-radio
              >
            </div>
            <div class="text-center mt-5">
              <button
                type="button"
                class="btn btn-purple button"
                @click="btnSearch"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </b-sidebar>
        <div class="p-3 bg-white mt-1 category-popover" v-if="show">
          <CategoryHierarchy
            ref="categoryHierarchy"
            :dataList="selectCategory"
            :catagories="catagories"
            :hasCreateBtn="false"
            @onDataChange="onDataChangeCategory"
          />
          <p class="text-danger text-center" v-if="errorCategory">
            {{ errorCategory }}
          </p>
          <div class="text-center mt-3">
            <b-button class="btn-main mx-1" @click="getDataByCategory"
              >ค้นหา</b-button
            ><b-button class="button btn-cancel mx-1" @click="show = false"
              >ยกเลิก</b-button
            >
          </div>
        </div>
        <b-row v-if="tierDetail.isDiscountAll == 2">
          <b-col>
            <b-row class="no-gutters mt-4">
              <b-col>
                <b-table
                  responsive
                  class="text-center table-list"
                  striped
                  :fields="fieldsCategory"
                  :items="dataExcel"
                  :busy="isBusy"
                  show-empty
                  empty-text="ไม่พบข้อมูล"
                >
                  <template v-slot:cell(sku)="data">
                    <p class="mb-2 mt-2 nobreak two-lines">
                      {{ data.item.sku }}
                    </p>
                  </template>
                  <template v-slot:cell(name)="data">
                    <p class="mb-2 mt-2 nobreak two-lines">
                      {{ data.item.name }}
                    </p>
                  </template>

                  <template v-slot:cell(productType)="data">
                    <p
                      v-if="data.item.productType == 1"
                      class="mb-2 mt-2 nobreak two-lines"
                    >
                      สินค้าทั่วไป
                    </p>
                    <p
                      v-else-if="data.item.productType == 2"
                      class="mb-2 mt-2 nobreak two-lines"
                    >
                      สินค้ามีตัวเลือก
                    </p>
                    <p
                      v-if="data.item.productType == 4"
                      class="mb-2 mt-2 nobreak two-lines"
                    >
                      สินค้ากลุ่ม
                    </p>
                  </template>

                  <template v-slot:cell(enabled)="data">
                    <p class="mb-2 mt-2 nobreak two-lines">
                      {{ data.item.enabled ? "เปิดใช้งาน" : "ปิดใช้งาน" }}
                    </p>
                  </template>
                  <template v-slot:cell(discountPercent)="data">
                    <p class="mb-2 mt-2 nobreak two-lines">
                      {{ data.item.discountPercent }} %
                    </p>
                  </template>

                  <template v-slot:cell(productPrice)="data">
                    <p class="mb-2 mt-2 nobreak text-nowrap">
                      <p class="m-0">฿ {{ data.item.productPrice | numeral("0,0.00") }}</p>
                    </p>
                  </template>

                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                </b-table>
                <p class="text-danger text-left" v-if="errorExcel == true">
                  กรุณาเพิ่มข้อมูล
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row class="px-3 mt-3">
          <b-col
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex">
              <label class="font-weight-bold main-label">
                การใช้งาน
                <span class="text-danger">*</span>
              </label>
              <div>
                <b-form-checkbox
                  switch
                  class="radio-active ml-3"
                  size="lg"
                  v-model="enabled"
                >
                  <span class="ml-2 main-label">
                    {{ enabled ? "เปิด" : "ปิด" }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
          <b-col class="d-flex justify-content-end align-items-center">
            <router-link :to="'/tier'">
              <b-button class="button btn-cancel ml-2"
                >ย้อนกลับ</b-button
              ></router-link
            >
            <b-button class="btn-main ml-3" @click="checkForm()"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Modal -->
    <!-- <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    /> -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalImportFile
      ref="modalImportFile"
      path="tier/ImportTierProduct"
      :tierId="id"
      colorBtnConfirm="primary"
      @success="importSuccess"
    />
  </div>
</template>

<script>
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import CategoryHierarchy from "@/components/categoryHierarchy/CategoryHierarchy";
import axios from "axios";
import * as moment from "moment/moment";
import ModalImportFile from "@/components/modal/ModalImportFile";

import {
  required,
  minValue,
  maxValue,
  requiredIf,
} from "vuelidate/lib/validators";

const validateMaxValue = (value) => {
  if (parseInt(value) <= 100) {
    return false;
  } else {
    return true;
  }
};

const validateMinValue = (value) => {
  if (parseInt(value) >= 0) {
    return false;
  } else {
    return true;
  }
};

export default {
  name: "TierDetails",
  components: {
    InputText,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
    ModalAlert,
    InputSelect,
    CategoryHierarchy,
    ModalImportFile,
  },
  data() {
    return {
      id: this.$route.params.id,
      modalMessage: "",
      enabled: true,
      dataExcel: [],
      dataCategoryHierarchy: {
        categoryIdList: [],
        isLast: false,
        selectId: 0,
        breadcrumb: [],
      },
      fields: [
        {
          key: "name",
          label: "หมวดหมู่หลัก",
          class: "w-100px text-nowrap",
        },
        {
          key: "action",
          label: "ส่วนลด",
          class: "w-100px text-nowrap",
        },
      ],
      fieldsCategory: [
        {
          key: "sku",
          label: "SKU",
          class: "w-100px text-nowrap",
        },
        {
          key: "name",
          label: "ชื่อสินค้า",
          class: "w-100px text-nowrap",
        },
        {
          key: "productType",
          label: "ประเภทสินค้า",
          class: "w-100px text-nowrap",
        },
        {
          key: "productStock",
          label: "จำนวนสินค้า",
          class: "w-50px text-nowrap",
        },
        {
          key: "enabled",
          label: "สถานะสินค้า",
          class: "w-100px text-nowrap",
        },
        {
          key: "productPrice",
          label: "ราคาสินค้า",
          class: "w-100px text-nowrap",
        },
        {
          key: "discountPercent",
          label: "ส่วนลด",
          class: "w-50px text-nowrap",
        },
      ],
      itemsDetail: [],
      tierDetail: {
        name: "",
        discountPercent: 0,
        isDiscountAll: 1,
        categortyTier: [],
        mainCategory: [],
      },
      isBusy: false,
      show: false,
      isLoadingDownload: false,
      errorExcel: false,
      error: [],
      errorCategory: "",
      rows: 0,
      filter: {
        PerPage: 10,
        PageNo: 1,
        startDate: null,
        endDate: null,
        Search: "",
        discountMin: 0,
        discountMax: 0,
        tierStatus: 1,
        fetchType: 2,
        category: [],
      },
      selectCategory: [],
      catagories: [],
    };
  },
  validations() {
    return {
      tierDetail: {
        name: { required },
        discountPercent: {
          required: requiredIf(function () {
            return this.tierDetail.isDiscountAll == 1;
          }),
          validateMinValue: function (value) {
            return !(
              validateMinValue(value) &&
              (this.tierDetail.isDiscountAll == 1 ||
                this.tierDetail.discountPercent != "")
            );
          },
          validateMaxValue: function (value) {
            return !(
              validateMaxValue(value) &&
              (this.tierDetail.isDiscountAll == 1 ||
                this.tierDetail.discountPercent != "")
            );
          },
        },
      },
      itemsDetail: {
        $each: {
          discountPercent: {
            required: requiredIf(function () {
              return this.tierDetail.isDiscountAll == 0;
            }),
            validateMaxValue: function (value) {
              return !(
                validateMaxValue(value) && this.tierDetail.isDiscountAll == 0
              );
            },
          },
        },
      },
    };
  },
  watch: {
    "tierDetail.isDiscountAll": async function (newValue, oldValue) {
      if (this.id != 0) {
        if (newValue == 0) {
          await this.getCategories();
        }
      }
      this.$v.tierDetail.$reset();
      this.$v.itemsDetail.$reset();
    },
  },
  created: async function () {
    this.$isLoading = false;
    await this.getList();
    await this.getCategories();
    this.$isLoading = true;
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      if (this.id != 0) {
        let resData = await this.$callApi(
          "post",
          `${this.$baseUrl}/api/tier/TierDetail/${this.id}`,
          null,
          this.$headers,
          this.filter
        );

        if (resData.result == 1) {
          this.tierDetail = resData.detail;
          if (this.tierDetail.isDiscountAll == 0) {
            this.itemsDetail = this.tierDetail.categortyTier;
          } else if (this.tierDetail.isDiscountAll == 2) {
            this.itemsDetail = this.tierDetail.productTier;
          }

          if (resData.detail.isDiscountAll == 2) {
            this.dataExcel = this.tierDetail.productTier;
          }
        }
      } else if (this.id == 0) {
        await this.getCategories();
      }
      this.isBusy = false;
    },
    getCategories: async function () {
      this.isBusy = true;
      let categories = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/category/categoryHierarchy`,
        null,
        this.$headers,
        null
      );
      if (categories.result == 1) {
        if (this.id == 0) {
          this.itemsDetail = categories.detail;
          this.itemsDetail.map((e) => {
            e.discountPercent = "";
            return e;
          });
        } else {
          this.catagories = categories.detail;
          if (
            this.tierDetail.isDiscountAll == 0 &&
            !this.tierDetail.categortyTier
          ) {
            this.itemsDetail = categories.detail;
          }
        }
      }
      this.isBusy = false;
    },
    downloadFile: async function () {
      this.isLoadingDownload = true;
      axios({
        url: `${this.$baseUrl}/api/tier/ExportTierProductTemplate`,
        method: "get",
        headers: this.$headers,
        responseType: "blob",
      })
        .then((response) => {
          this.$refs.modalLoading.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Category-Productgroup-` + dateExcel + `.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });

      this.isLoadingDownload = false;
    },
    importProduct: async function () {
      this.$refs.modalImportFile.show();
    },
    importSuccess: async function (data) {
      this.dataExcel = data.detail;
      this.errorExcel = false;
    },
    getDataByCategory() {
      var catLength = this.selectCategory.length;

      if (catLength > 0) {
        if (this.dataCategoryHierarchy.isLast != true) {
          this.errorCategory = "กรุณาเลือกหมวดหมู่ให้ครบทุกชั้น";
        } else {
          this.show = false;
          this.getList();
        }
      } else {
        this.errorCategory = "กรุณาเลือกหมวดหมู่";
      }
    },
    onDataChangeCategory(list) {
      this.dataCategoryHierarchy = { ...list };
      this.selectCategory = [...list.categoryIdList];
      this.filter.category = [];
      let select = this.selectCategory[this.selectCategory.length - 1];
      if (select) {
        this.filter.category.push(select);
      }
    },
    clearFilter() {
      this.filter.discountMin = 0;
      this.filter.discountMax = 0;
      this.filter.fetchType = 2;
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.$refs.filterSidebar.hide(true);
      this.filter.PageNo = 1;
      this.getList();
    },
    setDiscountAll(data) {
      // this.$v.tierDetail.discountPercent.$touch();
      // if (this.$v.tierDetail.discountPercent.$error) {
      //   return;
      // }
      this.tierDetail.discountPercent = 0;
      this.$v.itemsDetail.$reset();
      this.isBusy = true;
      this.tierDetail.mainCategory = [];
      this.tierDetail.categortyTier = [];
      for (const e of this.itemsDetail) {
        if (this.id == 0) {
          this.tierDetail.mainCategory.push({
            categoryId: e.id,
            discountPercent: data,
          });
        } else {
          let id = e.categoryId ? e.categoryId : e.id;
          this.tierDetail.categortyTier.push({
            categoryId: id,
            discountPercent: data,
          });
        }

        e.discountPercent = parseFloat(data);
      }
      setTimeout(() => {
        this.isBusy = false;
      }, 500);
      this.$v.tierDetail.discountPercent.$reset();
    },
    async setDiscount(index, data) {
      await this.checkValidate();
      if (this.id == 0) {
        let find = this.tierDetail.mainCategory.findIndex((e) => {
          return e.categoryId === this.itemsDetail[index].id;
        });

        if (find == -1) {
          this.tierDetail.mainCategory.push({
            categoryId: this.itemsDetail[index].id,
            discountPercent: data,
          });
        } else {
          this.tierDetail.mainCategory[find].discountPercent = data;
        }
      } else {
        let id = this.itemsDetail[index].categoryId
          ? this.itemsDetail[index].categoryId
          : this.itemsDetail[index].id;
        let find = this.tierDetail.categortyTier.findIndex((e) => {
          return e.categoryId === id;
        });

        if (find == -1) {
          this.tierDetail.categortyTier.push({
            categoryId: id,
            discountPercent: data,
          });
        } else {
          this.tierDetail.categortyTier[find].discountPercent = data;
        }
      }
    },
    async checkValidate() {
      if (this.tierDetail.isDiscountAll == 1) {
        return false;
      } else if (this.tierDetail.isDiscountAll == 0) {
        for (let index = 0; index < this.itemsDetail.length; index++) {
          if (
            this.itemsDetail[index].discountPercent != "" &&
            this.itemsDetail[index].discountPercent >= 0
          ) {
            this.error[index] = false;
            this.$v.itemsDetail.$each[index].$reset();
          } else {
            this.error[index] = true;
          }
        }
        if (this.error.every((v) => v === false)) return false;
        else return true;
      } else if (this.tierDetail.isDiscountAll == 2) {
        if (this.dataExcel.length == 0) {
          this.errorExcel = true;
          return true;
        } else {
          this.errorExcel = false;
          return false;
        }
      }
    },
    checkForm: async function () {
      this.$v.tierDetail.$touch();
      this.$v.itemsDetail.$touch();
      console.log(this.$v.itemsDetail)
      let check = await this.checkValidate();
      if (this.$v.tierDetail.$error || check) {
        this.$nextTick(() => {
          let target = document.querySelector(".text-error");
          if (target) {
            let domRect = document
              .querySelector(".text-error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 150
            );
          }
        });
        return;
      }

      const path =
        this.id == 0
          ? `${this.$baseUrl}/api/tier/AddTier`
          : `${this.$baseUrl}/api/tier/UpdateTier`;

      if (this.id == 0) delete this.tierDetail.categortyTier;

      if (this.tierDetail.isDiscountAll == 2) {
        delete this.tierDetail.mainCategory;
        delete this.tierDetail.categortyTier;

        let arr = [];
        for (const e of this.dataExcel) {
          arr.push({
            productId: e.id,
            discountPercent: e.discountPercent,
          });
        }
        this.tierDetail.ProductDiscount = arr;

        if (this.id != 0) {
          this.tierDetail.productTier = this.tierDetail.ProductDiscount;
        }
      }

      this.$refs.modalLoading.show();
      let resData = await this.$callApi(
        "post",
        path,
        null,
        this.$headers,
        this.tierDetail
      );

      this.$refs.modalLoading.hide();
      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/tier`,
          });
        }, 2000);
      } else {
        this.modalMessage = resData.message;
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      }
    },
  },
};
</script>

<style scoped>
.input-serach.error {
  border: 1px solid #e55353 !important;
}

.text-error,
.text-danger {
  color: #ff0000 !important;
  font-size: 14px;
}

.panel-input-serach {
  border: 1px solid #bcbcbc !important;
}

.search-modal {
  border: 2px solid #d0d0d0 !important;
}

.border-danger {
  border: 1px solid #e55353 !important;
}

label {
  color: #16274a;
  font-size: 17px;
  margin-bottom: 2px;
  width: 100%;
  font-weight: bold;
}

::v-deep .square-boxs {
  margin: auto;
  width: 75%;
  padding-top: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

::v-deep .dropdown-menu-right {
  width: 200px;
  padding: 5px;
}

::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

.category-box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: auto;
  padding: 5px 10px;
  max-width: 100%;
  text-align: left;
  cursor: pointer;
  border: 1px solid #bcbcbc !important;
}

.category-popover {
  position: absolute;
  z-index: 100;
  border: 1px solid #dcdcdc;
  overflow-y: auto;
  right: 0;
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.12),
    0 -1px 2px 0 rgba(0, 0, 0, 0.08);
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}

@media (max-width: 600px) {
  .category-main-box {
    width: 100%;
    margin-top: 10px;
  }

  .category-popover {
    right: 15px;
  }
}
</style>
